import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

export default function SocialIcons(props) {
	return (
		<StaticQuery
			query={graphql`
				{
					facebook: file(relativePath: { regex: "/facebook.png/" }) {
						childImageSharp {
							gatsbyImageData(width: 35, height: 35, layout: FIXED)
						}
					}
					insta: file(relativePath: { regex: "/insta.png/" }) {
						childImageSharp {
							gatsbyImageData(width: 35, height: 35, layout: FIXED)
						}
					}
					linkedin: file(relativePath: { regex: "/linkdin.png/" }) {
						childImageSharp {
							gatsbyImageData(width: 35, height: 35, layout: FIXED)
						}
					}
				}
			`}
			render={(data) => (
				<div className={props.className || 'social-icons'}>
					<a
						className="nav-link"
						href="https://www.instagram.com/uxologygroup/"
					>
						<GatsbyImage image={data.insta.childImageSharp.gatsbyImageData} />
					</a>
					<a
						className="nav-link"
						href="https://www.linkedin.com/company/uxologygroup/"
					>
						<GatsbyImage
							image={data.linkedin.childImageSharp.gatsbyImageData}
						/>
					</a>
					<a className="nav-link" href="https://www.facebook.com/UXologyGroup/">
						<GatsbyImage
							image={data.facebook.childImageSharp.gatsbyImageData}
						/>
					</a>
				</div>
			)}
		/>
	);
}
