import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Collapse, Navbar, Nav, NavItem } from 'reactstrap';

import SocialIcons from './social-icons';
import './header.scss';

import hamburgerIcon from '../images/hamburger-icon.svg';

export default class Header extends React.Component {
	constructor(props) {
		super(props);

		// console.log("Header constructed with props:", props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	render() {
		return (
			<StaticQuery
				query={graphql`
					{
						logoFile: file(relativePath: { regex: "/uxg-fp-logo.png/" }) {
							childImageSharp {
								gatsbyImageData(width: 250, height: 68, layout: FIXED)
							}
						}
					}
				`}
				render={(data) => this._render(data)}
			/>
		);
	}

	_normalize(url) {
		if (url === 'index') {
			return '/';
		}
		return `/${url}/`;
	}

	linkFor(url, title) {
		const currentUrl = this.props.page;
		const active = currentUrl === url;

		return (
			<NavItem>
				<Link
					to={this._normalize(url)}
					className={`nav-link ${active ? 'active' : ''}`}
				>
					{title}
				</Link>
			</NavItem>
		);
	}

	_render(data) {
		return (
			<>
				<Navbar dark className="site-navbar" fixed="top" expand="md">
					<Link className="navbar-brand" to="/">
						<GatsbyImage
							image={data.logoFile.childImageSharp.gatsbyImageData}
						/>
					</Link>
					<img
						className="navbar-toggler"
						src={hamburgerIcon}
						onClick={this.toggle}
						alt="hamburger icon"
					/>
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav navbar>
							{this.linkFor('index', 'Overview')}
							{this.linkFor('services', 'Research')}
							{this.linkFor('coaching', 'Coaching')}
							{this.linkFor('experience', 'Experience')}
							{this.linkFor('about', 'About')}
							{/* {this.linkFor('blog', 'Blog')} */}
							<NavItem className="social-wrap">
								<SocialIcons />
								<Link
									to="/contact/"
									state={{
										page:
											this.props.page === 'contact'
												? this.props.location?.state?.page
												: this.props.page,
									}}
								>
									<StaticImage
										className="contact"
										src="../images/contact-uxg.png"
									/>
								</Link>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
			</>
		);
	}
}
