/* eslint-disable no-eval */
/* eslint-disable no-empty */
/* eslint-disable import/no-mutable-exports */
// import 'bootstrap/scss/bootstrap.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';

import $ from 'jquery';

// Add icons we use elsewhere here for treeshaking and to import the SVG for use in the icon component
import {
	faChevronDown,
	faSpinner,
	faMapPin,
	faPhone,
	faEnvelope,
	// From services page
	faLightbulb,
	faFlask,
	faDesktop,
	faTachometerAlt,
	faCamera,
	faCog,
	faSearchPlus,
	faThList,
	faPlaneDeparture,
	faRoad,
	faBook,
	faUniversity,
	faSmile,
	faChartLine,
	faEye,
} from '@fortawesome/free-solid-svg-icons';

import Header from './header';
import './layout.scss';

library.add(
	faChevronDown,
	faSpinner,
	faMapPin,
	faPhone,
	faEnvelope,
	// From services page
	faLightbulb,
	faFlask,
	faDesktop,
	faTachometerAlt,
	faCamera,
	faCog,
	faSearchPlus,
	faThList,
	faPlaneDeparture,
	faRoad,
	faBook,
	faUniversity,
	faSmile,
	faChartLine,
	faEye,
);

global.$ = $;
try {
	eval('window.jQuery = $;');
} catch (e) {}

const PageColors = {
	index: '#33cc33',
	services: '#0004e2',
	coaching: '#75e6ff',
	experience: '#77f753',
	about: '#42f5f2',
	blog: '#1A25DC',
	contact: '#33cc33',
};

const PageTextColors = {
	index: '#fff',
	services: '#fff',
	experience: '#000',
	about: '#000',
	blog: '#fff',
	contact: '#fff',
	coaching: '#000',
};

const PageFooterColors = {
	index: '#0004e2',
	services: '#fff',
	coaching: '#0004e2',
	experience: '#0004e2',
	about: '#0004e2',
	blog: '#fff',
	contact: '#0004e2',
};

let currentPage;
let currentPageColor;
let currentPageTextColor;
export {
	PageColors,
	PageTextColors,
	currentPage,
	currentPageColor,
	currentPageTextColor,
	PageFooterColors,
};

class Layout extends React.Component {
	render() {
		currentPage = this.props.page;
		currentPageColor = PageColors[currentPage];
		currentPageTextColor = PageTextColors[currentPage];
		const page =
			this.props.page === 'contact'
				? this.props.location?.state?.page
				: this.props.page;

		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
							}
						}
					}
				`}
				render={(data) => (
					<>
						<Helmet
							title={data.site.siteMetadata.title}
							meta={[
								{
									name: 'description',
									content: 'A Leading User Experience Research Company',
								},
								{
									name: 'keywords',
									content: 'UXG, User Experience Research, UXology',
								},
							]}
						>
							<html lang="en" />
						</Helmet>
						<div
							style={{
								'--page-color': PageColors[page],
								'--page-text-color': PageTextColors[page],
								height: '100%',
							}}
						>
							<Header
								siteTitle={data.site.siteMetadata.title}
								page={this.props.page}
								location={this.props.location}
							/>
							<div className="page-wrap">{this.props.children}</div>
						</div>
					</>
				)}
			/>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
